import React, { Component } from 'react';
import { Link } from 'gatsby';
import Header from '../components/header11'
import Footer from '../components/footer4'
import ClientSlider1 from '../components/clientSlider1';
import PortfolioFlexible from '../components/portfolio_flexible';
// const bg = require('../images/duynhat/factory/DSC_3851_2.jpg')
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

class Flexible extends Component {


    render() {
        return (
            <>
                <Header/>
                <StaticQuery
                    query={graphql`
                    query {
                        desktop: file(relativePath: { eq: "duynhat/factory/DSC_3762.jpg" }) {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                        }
                    }
                    `}
                    render={data => {
                        return (
                <div class="page-content bg-white">
                    {/* <div class="dlab-bnr-inr overlay-black-middle"  style={{ backgroundImage: "url(" + bg + ")" }}> */}
                     
                    <BackgroundImage
                        className="dlab-bnr-inr overlay-black-middle"
                        fluid={data.desktop.childImageSharp.fluid}
                        >
                            <div class="dlab-bnr-inr overlay-black-middle ">
                                <div class="container">
                                    <div class="dlab-bnr-inr-entry">
                                        <h1 class="text-white">Flexible Packaging</h1>
                                        <div class="breadcrumb-row">
                                            <ul class="list-inline">
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/products">Products</Link></li>
                                                <li>Flexible Packaging</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </BackgroundImage>
                    {/* </div> */}
                    
                    <div class="content-block">
                        <div class="section-full content-inner bg-white">
                            <div class="container">
                                <div>
                                    <h2 class="text-black font-weight-600 m-b15">Flexible Packaging</h2>
                                    <p style={{marginBottom:12}}>Plastic packaging currently occupies a very important position in present society.</p>
                                    <p style={{marginBottom:12}}>Plastic packaging is usually odorless and tasteless. In some cases. it is almost 
                                        compulsory for protecting certain kinds of foods that require long shelf life such
                                         as frozen food kept in vacuumed bags. There are some types of packaging that achieve
                                          high rigidity, effective properties of resistance against moiture, chemical or to
                                           protect scent and flavor of product inside. These characteristics help to keep quality
                                            of packaged products from being dehydrated or rotten.</p>
                                    <p style={{marginBottom:12}}>Packaging can be transparent to make product visible or can be opaque,
                                         obscuring the light to protect food.</p>
                                     <p style={{marginBottom:12}}>Plastic packaging protects product against pasteurization or deep freezing temperatures.</p>
                                     <p style={{marginBottom:40}}> The most outstanding feature of plastic packaging is that it is lighter than all other packaging, very convenient in transportation, distribution. Plastic packaging are colorful and attractive to consumers as it can achieve high quality printing.
                                        </p>
                                </div>
                                   </div>
                        </div>
                        
                        
                    </div>
                    <PortfolioFlexible col="col-md-3"/>
                    <div class="section-full content-inner bg-white" style={{paddingTop:60}}>
                            <div class="container">
                            <div class="section-head text-black text-center">
                            <h2 class="title text-capitalize">Our Customers</h2>
                                <ClientSlider1
                                hideBorder
                                allowPadding
                                />
                            </div>
                            </div>
                        </div>
                </div>     
                   )
                }}
                />             
                <Footer/>                
            </>
        )
    }
}
export default Flexible;